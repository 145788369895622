import React, { useState } from "react"
import { ReicoCategory, ReicoProductInfo, ReicoTag, ReicoTarget } from "../../data/ReicoProducts"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"
import ProductImageCard from "../images/ProductImageCard"

export function tagToString(tag: ReicoTag): string {
  if(tag === "glutenfrei") return "glutenfrei"
  else if(tag === "getreidefrei") return "getreidefrei"
  else if(tag === "kalt-gepresst") return "kalt-gepresst"
  else if(tag === "kalt-geformt") return "kalt-geformt"
  else if(tag === "mineral") return "mineralisches Gleichgewicht"
  else if(tag === "lactosefrei") return "lactosefrei"
  else return "andere"
}

export function categoryMap(cat: ReicoCategory): string {
  if(cat == "Trocken") return "Trockenfutter"
  else if (cat == "Feucht") return "Feuchtfutter"
  else if (cat == "Kräuter") return "Kräuter"
  else if (cat == "Nahrungsergänzung") return "Nahrungsergänzung"
  return "Futter"
}

export function targetMap(target: ReicoTarget): {text: string, link: string} {
  if(target == "Human") return { text: "Für Menschen", link: "/Kategorie/FuerDenMenschen" }
  else if (target == "Cat") return { text: "Für Katzen", link: "/Kategorie/FuerKatzen" }
  return { text: "Für Hunde", link: "/Kategorie/FuerHunde" }
}

const Product = (props: {info: ReicoProductInfo}) => {

  return (
    <div className="mb-4">
    <Link to={"/Produkte/" + props.info.title}>
      <ProductImageCard fileName={props.info.image} alt={props.info.title} className="p-3 clickable">
        <div style={{ height: 300, width: 250}}>
          <div
            className="p-3"
            style={{ textAlign: "center", backgroundColor: "rgba(255,255,255,0.9)", position: "absolute", bottom: 0, left: 0, right: 0, height: 120 }}>
            <p style={{fontWeight: 600}} className="card-title">{props.info.title}</p>
            <p>{props.info.subtitle}</p>
          </div>
          <div style={{position: "absolute", top: 20, right: 0}}>
            {
              props.info.tags.map((tag, i) => (
                <div key={i} className="p-1 mb-1" style={{ fontSize: 13, fontWeight: 700, backgroundColor: "rgba(129, 199, 132, 0.9)", borderRadius: "5px 0px 0px 5px"}}>
                  <FontAwesomeIcon icon={faCheck} className="mx-1"/>
                  {tagToString(tag)}
                </div>
              ))
            }
          </div>
        </div>

      </ProductImageCard>
    </Link>
    </div>)

}

export default Product