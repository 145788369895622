import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

type Props = {
  fileName: string,
  alt: string,
  className?: string,
  children: any
}

const ProductImageSmall = (props: Props) => {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, dir: {regex: "/Products/"}}) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
                originalName
              }
            }
          }
        }
      }
    }
  `)

  const {fileName, alt, children} = props

  const className = props.className || ""

  const gatsbyImage = allFile.edges
    .map((edge: any) => edge.node.childImageSharp.fluid)
    .find((x: any) => x.originalName === fileName)

  return (
    <BackgroundImage
      Tag="div"
      className={`card ${className}`}
      fluid={gatsbyImage}
      alt={alt}
      >
      {children}
    </BackgroundImage>
  )
}

export default ProductImageSmall;
