import React from "react"
import { Breadcrumb, Card, Col, Row } from "react-bootstrap"
import PageWrapper from "../components/PageWrapper"
import Product, { categoryMap, tagToString, targetMap } from "../components/content/Product"
import { ReicoProductInfo } from "../data/ReicoProducts"
import ProductImageLarge from "../components/images/ProductImageLarge"
import Button from "../components/basic/Button"
import { faArrowLeft, faCheck, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ContactCard from "../components/content/ContactCard"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"

const ProductPage = (props: {pathContext: {product: ReicoProductInfo}}) => {

  const info = props.pathContext.product

  const targetInfo = targetMap(info.target[0])

  const byProductText = info.byProduct ? (
    "Unsere tierischen Nebenerzeugnisse sind hochwertig " +
    "und bestehen bei dieser Sorte aus: " +
    info.byProduct
  ) : "Kein tierischen Nebenerzeugnisse enthalten!"

  return (
    <PageWrapper title={info.title}>
      <section id="produkt" className="mb-4">
      <div className="mb-4">
        <Breadcrumb listProps={{style: {backgroundColor: "transparent"}}}>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/#produkte">Produkte</Breadcrumb.Item>
          <Breadcrumb.Item href={targetInfo.link}>{targetInfo.text}</Breadcrumb.Item>
          <Breadcrumb.Item href="">{info.title}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Row className="justify-content-center mt-5">
        <Col lg={8}>
            <h2>{info.title}</h2>
            <h4 className="mb-5" style={{color: "rgba(0,0,0,0.67"}}>{info.subtitle}</h4>
            <div className="mb-5">
              <div className="position-relative">
                <div style={{margin: "0px auto", width: 400, maxWidth: "90vw", maxHeight: 500, borderRadius: 10, overflow: "hidden"}}>
                  <ProductImageLarge fileName={info.image} alt={info.title} />
                </div>
                <div style={{position: "absolute", left: 20, top: 20}}>
                  {
                    info.tags.map((tag) => (
                      <div>
                  <span className="p-2 mb-1 mr-2 d-inline-block"
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          backgroundColor: "rgba(129, 199, 132, 0.9)",
                          borderRadius: "5px 5px 5px 5px"
                        }}>
                  <FontAwesomeIcon icon={faCheck} className="mx-1"/>
                    {tagToString(tag)}
                </span>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
            {
              info.description.map((x, i) => <p key={i}>{x}</p>)
            }
            <h6>Zusammensetzung</h6>
            {

              Array.isArray(info.composition) ? (
                info.composition.map((comp) => (
                  <p>{comp}</p>
                ))
              ) : <p>{info.composition}</p>

            }
            <h6>Tierische Nebenerzeugnisse</h6>
            <p>{byProductText}</p>

            <div className="d-flex justify-content-center mt-5">
              <Button icon={faArrowLeft} text="Zu anderen Produkten" href="/#produkte" internal/>
            </div>
        </Col>
      </Row>
      </section>
      <section id="contact" className="mb-4">
        <Row className="justify-content-center">
          <Col lg={8}>
            <hr className="mb-4"/>
            <h2>Interesse?</h2>
            <p className="mb-4">
              Wir von Reico legen großen Wert auf persönliche Beratung.
            </p>

            <p className="mb-4">
              Wenn Sie sich für eines der Produkte interessieren
              oder Sie sich einfach beraten lassen möchten, dann können Sie mich
              per Whatsapp/Telefon <strong className="d-inline-block">+49 175 4150950</strong> {" "}
              oder per Email unter <strong className="d-inline-block">kim.schamberger@outlook.de</strong> erreichen:
            </p>

            <Row>
              <Col md={{span: 4, offset: 0}} xs={{span: 10, offset: 1}} className="mb-3">
                <ContactCard backgroundColor="rgba(0,0,0,0.8)" href="https://wa.me/491754150950" icon={faWhatsapp} iconSize="3x">
                  <strong>Whatsapp</strong>
                </ContactCard>
              </Col>
              <Col md={{span: 4, offset: 0}} xs={{span: 10, offset: 1}} className="mb-3">
                <ContactCard backgroundColor="rgba(0,0,0,0.8)" href="tel:+49175-415-0950" icon={faPhone} iconSize="2x">
                  <strong>Anruf</strong>
                </ContactCard>
              </Col>
              <Col md={{span: 4, offset: 0}} xs={{span: 10, offset: 1}} className="mb-3">
                <ContactCard backgroundColor="rgba(0,0,0,0.8)" href="mailto:kim.schamberger@outlook.de" icon={faEnvelope} iconSize="2x">
                  <strong>Neue Email</strong>
                </ContactCard>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

    </PageWrapper>
  )

}

export default ProductPage