import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

type Props = {
  fileName: string,
  alt: string
}

const ProductImageLarge = ({fileName, alt}: Props) => {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, dir: {regex: "/Products/"}}) {
        edges {
          node {
            id
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
                originalName
              }
            }
          }
        }
      }
    }
  `)

  const gatsbyImage = allFile.edges
    .map((edge: any) => edge.node.childImageSharp.fluid)
    .find((x: any) => x.originalName === fileName)

  return (
    <Img
      fluid={gatsbyImage}
      alt={alt}
      style={{maxHeight: 600}}
      imgStyle={{ objectFit: "contain" }}
    />
  )
}

export default ProductImageLarge;
